import React from 'react';

function GridInterface(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill} strokeLinecap="butt" strokeLinejoin="miter">
		<rect height="7" width="7" fill="none" rx="1" ry="1" stroke={fill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth={strokewidth} x="3" y="3"/>
		<rect height="7" width="7" fill="none" rx="1" ry="1" stroke={secondaryfill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth={strokewidth} x="3" y="14"/>
		<rect height="7" width="7" fill="none" rx="1" ry="1" stroke={secondaryfill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth={strokewidth} x="14" y="3"/>
		<rect height="7" width="7" fill="none" rx="1" ry="1" stroke={fill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth={strokewidth} x="14" y="14"/>
	</g>
</svg>
	);
};

export default GridInterface;