import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Button,
    Paper,
    Chip,
    ButtonGroup,
    Grid,
} from "@mui/material";
import Layout from "../theme/layout";
import Icon from "../theme/vectors/bundle";
import videos from "../blogdata.json";

const KnowledgeDetailPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [videoData, setVideoData] = useState(null);
    const [selectedServer, setSelectedServer] = useState("server1");
    const videoRef = useRef(null);

    useEffect(() => {
        const foundVideo = videos.find((video) => video.id === id);
        setVideoData(foundVideo);
    }, [id]);

    const handleResize = useCallback(() => {
        if (videoRef.current) {
            const ratio = 1.77;
            const width = videoRef.current.offsetWidth;
            const height = width > 630 ? width / ratio : width * ratio;
            videoRef.current.style.height = `${height}px`;
        }
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize(); // Call once to set initial size

        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    useEffect(() => {
        // Trigger resize when server is switched
        handleResize();
    }, [selectedServer, handleResize]);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleServerChange = (server) => {
        setSelectedServer(server);
    };

    if (!videoData) {
        return (
            <Layout>
                <Button
                    startIcon={<Icon name="leftChevron" size={15} />}
                    onClick={handleBackClick}
                    sx={{ mb: 2 }}
                >
                    Quay lại
                </Button>
                <Typography variant="h1">Không tìm thấy nội dung!</Typography>
            </Layout>
        );
    }

    return (
        <Layout>
            <Button
                startIcon={<Icon name="leftChevron" size={15} />}
                onClick={handleBackClick}
                sx={{ mb: 2 }}
            >
                Quay lại
            </Button>

            <h1>{videoData.title}</h1>
            <p>{videoData.author}</p>

            <Box sx={{ mb: 2 }}>
                {videoData.tags.map((tag) => (
                    <Chip key={tag} label={tag} sx={{ mr: 1, mb: 1 }} />
                ))}
            </Box>

            <Grid container spacing={4}>
                <Grid item xs={12} xl={8}>
                    <Box sx={{ mb: 3 }}>
                        <p><strong>Nghe Audio :</strong></p>

                        <audio controls style={{ width: "100%" }}>
                            <source
                                src={videoData.audioSource}
                                type="audio/wav"
                            />
                            Your browser does not support the audio element.
                        </audio>
                    </Box>

                    <Box sx={{ mb: 10 }} ref={videoRef}>
                        <p><strong>Xem video :</strong></p>

                        <ButtonGroup variant="contained" sx={{ mb: 2 }}>
                            <Button
                                onClick={() => handleServerChange("server1")}
                                variant={
                                    selectedServer === "server1"
                                        ? "contained"
                                        : "outlined"
                                }
                            >
                                Server 1
                            </Button>
                            <Button
                                onClick={() => handleServerChange("server2")}
                                variant={
                                    selectedServer === "server2"
                                        ? "contained"
                                        : "outlined"
                                }
                            >
                                Server 2
                            </Button>
                        </ButtonGroup>

                        {selectedServer === "server1" ? (
                            <video width="100%" controls>
                                <source src={videoData.mySource} />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${videoData.id}`}
                                title={videoData.title}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        )}
                    </Box>
                </Grid>

                <Grid item xs={12} xl={4}>
                    <Box sx={{ mb: 3 }}>
                        {videoData.summary.length > 0 && (
                            <>
                                <p><strong>Nội dung tóm tắt :</strong></p>
                                <Typography variant="body1" paragraph>
                                    {videoData.summary}
                                </Typography>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default KnowledgeDetailPage;
