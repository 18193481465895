import React from "react";
import Icon from "../theme/vectors/bundle";

const Test = () => {

    return (
        <>
            <Icon name="addBold" />
            <Icon name="edit" />
            <Icon name="delete" />
            <Icon name="remove" />
            <Icon name="search" />
            <Icon name="view" />
            <Icon name="hide" />
            <Icon name="rightChevron" />
            <Icon name="leftChevron" />
            <h1>Test page</h1>
        </>
    );
};

export default Test;