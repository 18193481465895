import React from 'react';
import { Chip, Box } from '@mui/material';

const TagFilter = ({ tags, selectedTags, onTagSelect }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
      {tags.map((tag) => (
        <Chip
          key={tag}
          label={tag}
          onClick={() => onTagSelect(tag)}
          color={selectedTags.includes(tag) ? 'primary' : 'default'}
          variant={selectedTags.includes(tag) ? 'filled' : 'outlined'}
        />
      ))}
    </Box>
  );
};

export default TagFilter;