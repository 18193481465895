import { useState, useMemo } from 'react';

const useVideoFilter = (videos) => {
    const [selectedTags, setSelectedTags] = useState([]);

    const handleTagSelect = (tag) => {
        setSelectedTags((prev) =>
            prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
        );
    };

    const filteredVideos = useMemo(() => {
        const filtered = videos.filter(
            (video) =>
                selectedTags.length === 0 ||
                selectedTags.every((tag) => video.tags.includes(tag))
        );

        return filtered.sort((a, b) => {
            // If both videos have an index, sort by index in descending order
            if (a.index !== undefined && b.index !== undefined) {
                return b.index - a.index;
            }
            // If only a has an index, it should come first
            if (a.index !== undefined) {
                return -1;
            }
            // If only b has an index, it should come first
            if (b.index !== undefined) {
                return 1;
            }
            // If neither has an index, maintain their original order
            return 0;
        });
    }, [videos, selectedTags]);

    return { filteredVideos, selectedTags, handleTagSelect };
};

export default useVideoFilter;