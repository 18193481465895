import React from 'react';

import Add from "./icons/e-add-2";
import AddBold from "./icons/e-add";
import Delete from "./icons/e-delete-2";
import DeleteBold from "./icons/e-delete";
import Edit from "./icons/d-edit-2";
import EditBold from "./icons/d-edit";
import Remove from "./icons/e-remove-2";
import RemoveBold from "./icons/e-remove";
import Search from "./icons/magnifier";
import View from "./icons/eye-open";
import Hide from "./icons/eye-closed";
import RightChevron from "./icons/chevron-right";
import LeftChevron from "./icons/chevron-left";
import UpChevron from "./icons/chevron-up";
import DownChevron from "./icons/chevron-down";
import DragHand from "./icons/hand";
import FileContent from "./icons/file-content";
import Folder from "./icons/folder";
import DataUpload from "./icons/data-upload-2";
import ChartBar from "./icons/chart-bar-trend-up";
import GridInterface from "./icons/grid-interface";
import Paragraph from "./icons/paragraph";
import GlobePointer from "./icons/globe-pointer";

const iconComponents = {
    add: Add,
    addBold: AddBold,
    delete: Delete,
    deleteBold: DeleteBold,
    edit: Edit,
    editBold: EditBold,
    remove: Remove,
    removeBold: RemoveBold,
    search: Search,
    view: View,
    hide: Hide,
    rightChevron: RightChevron,
    leftChevron: LeftChevron,
    upChevron: UpChevron,
    downChevron: DownChevron,
    dragHand: DragHand,
    fileContent: FileContent,
    folder: Folder,
    dataUpload: DataUpload,
    chartBar: ChartBar,
    gridView: GridInterface,
    listView: Paragraph,
    globePointer: GlobePointer,
};

const Icon = ({ name, size = 18, color, ...props }) => {
    const IconComponent = iconComponents[name];

    if (!IconComponent) {
        console.warn(`Icon "${name}" not found`);
        return null;
    }

    return (
        <IconComponent
            width={size}
            height={size}
            fill={color}
            {...props}
        />
    );
};

export default Icon;