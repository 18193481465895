export const transformImage = (url) => {
    const urlObject = new URL(url);
    urlObject.pathname = `/cdn-cgi/image/fit=scale-down,format=avif${urlObject.pathname}`;
    return urlObject.toString();
};

export const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num) => num.toString().padStart(2, '0');

    if (hours > 0) {
        return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
    } else {
        return `${pad(minutes)}:${pad(remainingSeconds)}`;
    }
};