import React, { useState, useEffect, useRef } from 'react';
import { Box, Skeleton } from '@mui/material';

const LazyImage = ({ src, alt, height, sx }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isInView, setIsInView] = useState(false);
    const imageRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsInView(entry.isIntersecting);
            },
            {
                rootMargin: "350px 0px 350px 0px",
                threshold: 0.01,
            }
        );

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        return () => {
            if (imageRef.current) {
                observer.unobserve(imageRef.current);
            }
        };
    }, []);

    const handleLoad = () => {
        setIsLoading(false);
    };

    return (
        <Box ref={imageRef} sx={{ position: "relative", height, ...sx }}>
            {isLoading && (
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height="100%"
                    width="100%"
                    sx={{ position: "absolute", top: 0, left: 0, right: 0 }}
                />
            )}
            {isInView && (
                <Box
                    component="img"
                    src={src}
                    alt={alt}
                    onLoad={handleLoad}
                    sx={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        display: isLoading ? "none" : "block",
                    }}
                />
            )}
        </Box>
    );
};

export default LazyImage;