import React from 'react';
import { Grid, Card, CardContent, Typography, Chip } from '@mui/material';
import { transformImage, formatDuration } from "../utils";
import LazyImage from './lazyimage';

const GridView = ({ videos, onCardClick }) => {
    return (
        <Grid container spacing={4}>
            {videos.map((video, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
                    <Card
                        onClick={() => onCardClick(video.id)}
                        sx={{
                            cursor: "pointer",
                            "&:hover": {
                                boxShadow: 6,
                            },
                        }}
                    >
                        <LazyImage
                            src={transformImage(video.myThumbnail)}
                            alt={video.title}
                            height="140"
                        />
                        <CardContent>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    lineHeight: 1.5,
                                    height: "3em",
                                    mb: 1,
                                }}
                            >
                                {video.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {video.author}
                            </Typography>
                            {video.tags.length > 0 && (
                                <Typography variant="body2" color="text.secondary">
                                    Chủ đề: {video.tags.join(", ")}
                                </Typography>
                            )}
                            <Chip
                                label={formatDuration(video.duration)}
                                size="small"
                                sx={{ mt: 1 }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default GridView;