import React from 'react';
import { List, ListItem, Box, Typography, Chip } from '@mui/material';
import { transformImage, formatDuration } from "../utils";
import LazyImage from './lazyimage';

const ListView = ({ videos, onCardClick }) => {
    return (
        <List>
            {videos.map((video, index) => (
                <ListItem
                    key={index}
                    divider
                    onClick={() => onCardClick(video.id)}
                    sx={{
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "action.hover",
                        },
                    }}
                >
                    <Box sx={{ display: "flex", width: "100%" }}>
                        <LazyImage
                            src={transformImage(video.myThumbnail)}
                            alt={video.title}
                            height="100"
                            sx={{ width: "150px", marginRight: 2 }}
                        />
                        <Box>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    lineHeight: 1.5,
                                    height: "3em",
                                    mb: 1,
                                }}
                            >
                                {video.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {video.author}
                            </Typography>
                            {video.tags.length > 0 && (
                                <Typography variant="body2" color="text.secondary">
                                    Chủ đề: {video.tags.join(", ")}
                                </Typography>
                            )}
                            <Chip
                                label={formatDuration(video.duration)}
                                size="small"
                                sx={{ mt: 1 }}
                            />
                        </Box>
                    </Box>
                </ListItem>
            ))}
        </List>
    );
};

export default ListView;