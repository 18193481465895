import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    Chip,
} from "@mui/material";
import Layout from "../theme/layout";
import Icon from "../theme/vectors/bundle";
import videos from "../blogdata.json";
import useVideoFilter from "../hooks/useVideoFilter";
import TagFilter from "../theme/partials/tagfilter";
import GridView from "../theme/partials/gridview";
import ListView from "../theme/partials/listview";

const Page = () => {
    const navigate = useNavigate();
    const [viewMode, setViewMode] = useState("grid");
    const { filteredVideos, selectedTags, handleTagSelect } = useVideoFilter(videos);

    const allTags = [...new Set(videos.flatMap((video) => video.tags))];

    const handleViewModeChange = (event, newViewMode) => {
        if (newViewMode !== null) {
            setViewMode(newViewMode);
        }
    };

    const handleCardClick = (videoId) => {
        navigate(`/knowledge/${videoId}`);
    };

    return (
        <Layout>
            <h1>Kiến thức - Phát triển bản thân</h1>
            
            {allTags.length > 0 && (
                <Paper sx={{ p: 2, mb: 3 }}>
                    <Box>
                        {allTags.map((tag) => (
                            <Chip
                                key={tag}
                                label={tag}
                                onClick={() => handleTagSelect(tag)}
                                color={selectedTags.includes(tag) ? "primary" : "default"}
                                sx={{ m: 0.5 }}
                            />
                        ))}
                    </Box>
                </Paper>
            )}

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                }}
            >
                <TagFilter
                    tags={allTags}
                    selectedTags={selectedTags}
                    onTagSelect={handleTagSelect}
                />
                <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewModeChange}
                    aria-label="view mode"
                >
                    <ToggleButton value="grid" aria-label="grid view">
                        <Icon name="gridView" size={15} />
                    </ToggleButton>
                    <ToggleButton value="list" aria-label="list view">
                        <Icon name="listView" size={15} />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {viewMode === "grid" ? (
                <GridView videos={filteredVideos} onCardClick={handleCardClick} />
            ) : (
                <ListView videos={filteredVideos} onCardClick={handleCardClick} />
            )}
        </Layout>
    );
};

export default Page;