import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import TestPage from "./pages/test";
import KnowledgePage from "./pages/knowledge";
import KnowledgeDetailsPage from "./pages/knowledgeDetails";
// import StatisticsPage from "./pages/statistics";
// import MatchesPage from "./pages/matches";
// import MatchDetailPage from "./pages/matchDetails";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/knowledge" element={<KnowledgePage />} />
                <Route path="/knowledge/:id" element={<KnowledgeDetailsPage />} />
                <Route path="/economicdata" element={<TestPage />} />
                <Route path="/news" element={<TestPage />} />
                {/* <Route path="/statistics" element={<StatisticsPage />} />
                <Route path="/matches" element={<MatchesPage />} />
                <Route path="/match/:id" element={<MatchDetailPage />} /> */}

                <Route path="*" element={<Navigate to="/knowledge" />} />
            </Routes>
        </Router>
    );
};

export default App;
 